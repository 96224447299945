import { type ITemplate, ShapeType, TokenType } from "@framework/types";
import { emptyStateString } from "@dzambalaorg/draft-js-utils";
import { emptyPrice, getEmptyTemplate } from "@dzambalaorg/mui-inputs-asset";
import { defaultValues, timeValues } from "./constants";
import { Time } from "../../../shared";

export const emptyValues = {
  title: "",
  description: emptyStateString,
  content: getEmptyTemplate(TokenType.ERC20),
  template: {
    price: emptyPrice,
    cap: "0",
  } as ITemplate,
  duration: timeValues[Time.YEAR],
  shape: ShapeType.LINEAR,
  period: 1,
  ...defaultValues,
};
