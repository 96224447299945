import React from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router";

import { FormWrapper } from "@dzambalaorg/mui-form";
import { useApi } from "@dzambalaorg/provider-api-firebase";
import { PageHeader } from "@dzambalaorg/mui-page-layout";
import { IVestingBox } from "@framework/types";
import { cleanUpAsset } from "@framework/exchange";

import { emptyValues, validationSchema, VestingBoxForm } from "../../../../../components/forms";
import { Breadcrumbs } from "../../../../../components/breadcrumbs";

export const CreateVestingBox = () => {
  const navigate = useNavigate();
  const api = useApi();

  const handleConfirm = async (values: IVestingBox, form: any) => {
    const {
      content,
      template,
      period,
      growthRate,
      cliff,
      afterCliffBasisPoints,
      imageUrl,
      title,
      description,
      shape,
      duration,
      wallet,
    } = values;

    const preparedValues = {
      title,
      description,
      imageUrl,
      shape,
      duration,
      growthRate,
      wallet,
      content: cleanUpAsset(content),
      price: cleanUpAsset(template?.price),
      contractId: template?.contractId,
      period: period || 1,
      cliff: cliff || 0,
      afterCliffBasisPoints: afterCliffBasisPoints || 0,
      cap: template?.cap.toString() || "0",
    };

    await api
      .fetchJson({
        url: "/vesting/boxes",
        method: "POST",
        data: preparedValues,
      })
      .then(() => {
        form.reset();
        navigate(-1);
        return null;
      });
  };

  return (
    <Grid>
      <Breadcrumbs path={["dashboard", "vesting", "vesting.boxes", "vesting.create"]} />
      <PageHeader message="pages.vesting.create.title" />
      <FormWrapper initialValues={emptyValues} onSubmit={handleConfirm} validationSchema={validationSchema}>
        <VestingBoxForm />
      </FormWrapper>
    </Grid>
  );
};
